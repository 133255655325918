import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useStoryblok, renderRichText, getStoryblokApi } from "@storyblok/react";
import { SET_MAINTENANCE, SET_PRODUCTS } from 'store/actions';
import { useDispatch } from 'react-redux';

import config from 'config';

const useMaintenance = () =>  {
  
  const dispatch = useDispatch();
  const cc = 'fr'; // TODO get from domain namee

  const current_lang = useSelector((state) => state.customization.locale);

  const full_locale = config.simple_locale_codes ? current_lang : cc === 'en' ? 'undefined' : `${current_lang}-${cc}`;

  const maint_for_this_site = 'admin/' + window.location.hostname.replace('www.','').replace(/\./g, '-');

  const [maintenance, setMaintenance] = useState({}); // we could use SWR or usePersistenState here ?
 
  const settings_info = useStoryblok(maint_for_this_site, { resolve_relations: "settings.category_order", language: full_locale });
  // const all_products = useStoryblok('', {language: full_locale, content_type: 'product', filter_query: { available_in: {in: market}} });
  // const all_products = useStoryblok('', {language: full_locale, content_type: 'product'});
  // const all_products = useStoryblok('', {per_page: 100, language: full_locale, content_type: 'product', filter_query: { available_in: {any_in_array: market}} }) ;
  
  // const usa_products = useStoryblok('', {per_page: 100, content_type: 'product', filter_query: { available_in: {any_in_array: "USA"}} }) ;
  
  useEffect(() => {  
    const extract_content = (st) => {
      const pr = st.content;
      pr.categories = pr.categories.map(cat_id => settings_info.content.category_order.find(c => c.uuid == cat_id)?.content);
      pr.language = st.lang;
      return pr;
    };
    const get_products = async () => {
      const sb_api = getStoryblokApi();
      const market = settings_info?.content?.market ?? 'FR';
      let has_next_page = true;
      let p = 1;
      let product_stories = [];
      while( has_next_page ){
        const new_products = await sb_api.get(`cdn/stories/`, {per_page: 100, page: p, language: full_locale, content_type: 'product', filter_query: { available_in: {any_in_array: market}}  });
        product_stories = product_stories.concat(new_products?.data?.stories);
        has_next_page = new_products.total > 100 * p;
        p++;
      }
      const products = product_stories.map(st => extract_content(st));
      dispatch({ type: SET_PRODUCTS, products });

      console.log(products);
    }
    const rendered_message = renderRichText(settings_info?.content?.message);
    if (!settings_info?.content) {
      console.log('setting null');
      dispatch({ type: SET_MAINTENANCE, payload: null });
    } else {

      get_products();
      dispatch({ type: SET_MAINTENANCE, payload: {...settings_info.content, message: rendered_message}});
    }
    setMaintenance( {...settings_info?.content, message: rendered_message} )
  }, [dispatch, settings_info, current_lang]);

  return maintenance;
}

export default useMaintenance;