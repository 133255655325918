
const shop_currency =   !!process.env?.REACT_APP_CURRENCY ? process.env.REACT_APP_CURRENCY : 'EUR';
const spokenLanguages = !!process.env?.REACT_APP_LANGUAGES ? process.env.REACT_APP_LANGUAGES.split('/') : ['en']; // ?? ['en', 'es'];
const sb_publ_token =   !!process.env?.REACT_APP_SB_PUBL_TOKEN ? process.env.REACT_APP_SB_PUBL_TOKEN :'8JYWY4F9u0Gtdvl7CazIpQtt';
const sb_region =       !!process.env?.REACT_APP_SB_REGION ? process.env.REACT_APP_SB_REGION : 'us';
const api_endpoint =    !!process.env?.REACT_APP_API_SERVER ? process.env.REACT_APP_API_SERVER : '';

// TODO: move all there links to CMS bases settings !
const ext_links = {
    consumers: "https://www.philips.${locale}/c-m-mo/philips-avent-and-your-baby",
    professionals: "https://www.philips.${locale}/c-m-mo/philips-avent-healthcare-professional",
    dp_marketing: "https://www.philips.${locale}/c-m-mo/philips-avent-healthcare-professional#_contact",
    dp_sales: "https://www.philips.${locale}/c-m-mo/philips-avent-healthcare-professional#_contact",
    about: "https://www.philips.${locale}/a-w/about"
};

// if( shop_currency != 'USD' ){
//     ext_links.consumers =  "https://www.philips.ca/${locale}/c-e/pe/oral-healthcare.html";
//     // TODO: find teh correct CAN version for these links
//     ext_links.dp_marketing =  "https://www.philips.ca/${locale}/c-m-pe/dental-professionals#_marketing_form";
//     ext_links.dp_sales =  "https://www.philips.ca/${locale}/c-w/support-home/support-contact-page.html";
//     ext_links.about = "https://www.philips.ca/${locale}/a-w/about.html";
    
//     ext_links.terms_of_use = "/${locale}/terms_of_use.pdf";
//     ext_links.terms_and_conditions = "/${locale}/terms_and_conditions.pdf";

//     ext_links.imported = {
//         company: "Discus Dental Canada (DDC)",
//         address: "2830 Argentia Road, Unit 728",
//         city: "Mississauga, ON L5N 8G4",
//         phone: "(800) 278-8282",
//         href: "https://www.philips.ca/c-e/pe/dental-professionals.html" 
//     };
//     ext_links.produced = {
//         company: "Discus Dental, LCC",
//         address: "Ontario, CA 91761 USA",
//         // phone: "(800) 278-8282",
//         href: "https://www.usa.philips.com/c-m-pe/dental-professionals/" 
//     };
//     // banner should be available in two languages before we can use it in CAN
//     ext_links.__banner_login = {
//         imagepath: "Proshop-Module-Banner",
//         colwidth : [7,4,1],
//         url: "https://www.philips.ca/${locale}/c-e/pe/dental-professionals.html#_sales_form_ohc"
//     };

// } else { // only USA ...
//     // ext_links.terms_of_use = "/${locale}/terms_of_use.pdf";
//     ext_links.terms_and_conditions = "/us/terms_and_conditions.pdf";

//     ext_links.banner_login = {
//         imagepath: "Proshop-Module-Banner",
//         colwidth : [7,4,1],
//         url: "https://www.usa.philips.com/c-m-pe/dental-professionals#_sales_form"
//     };
//     ext_links.banner_shop = {
//         imagepath: "Proshop-Module-Banner",
//         colwidth : [7,4,1],
//         url: "https://www.usa.philips.com/c-m-pe/dental-professionals#_sales_form"
//     };

// }

const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    basename: '',
    defaultPath: '/',
    fontFamily: `'NeueFreutiger World', sans-serif`,
    borderRadius: 6,
    outlinedFilled: true,
    theme: 'light', 
    presetColor: 'theme1',
    i18n: spokenLanguages?.[0] ?? 'en',
    simple_locale_codes: shop_currency == 'EUR',
    rtlLayout: false,
    spokenLanguages: spokenLanguages,
    currencyCode: shop_currency,
    support: {
        phone: '800-422-9448',
        phone_link: 'tel:800-422-9448',
        email: 'avent.customerservice@philips.com'
    },
    api_endpoint: api_endpoint,
    sb_publ_token: sb_publ_token,
    sb_region: sb_region,
    links: ext_links
};

export default config;
