import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import ZoomDeliveredPage from 'views/pages/zoomDelivered';

// sample page routing
const HomePage = Loadable(lazy(() => import('views/pages/home')));
const ShopPage = Loadable(lazy(() => import('views/pages/shop')));
const ProductPage = Loadable(lazy(() => import('views/pages/product')));
const CartPage = Loadable(lazy(() => import ('views/pages/cart')));
const ProfilePage = Loadable(lazy(() => import('views/pages/profile')));
const OrderOnBehalfPage = Loadable(lazy(() => import('views/pages/orderonbehalf')));

// ==============================|| ROUTING available AFTER AUTHENTICATING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>            
            <MainLayout />                        
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <HomePage />
        },    
        {
            path: '/c-p',
            element: <ShopPage />
        },
        {
            path: '/c-p/:category/:id',
            element: <ShopPage />
        },
        {
            path: '/c-p/:category/:id/:ctn',
            element: <ProductPage />
        },
        {
            path: '/c-p/:ctn',
            element: <ProductPage />
        },
        {
            path: '/cart',
            element: <CartPage />
        },
        {
            path: '/order-on-behalf',
            element: <OrderOnBehalfPage />
        },
        {
            path: '/profile',
            element: <ProfilePage />,
            children: [
                {
                    path: '/profile/my-account'                
                },
                {
                    path: '/profile/my-orders'                    
                }
            ]
        },
        // {
        //     path: '/zoom-delivered',
        //     element: <ZoomDeliveredPage />
        // },
    ]
};

export default MainRoutes;
